import { AxiosResponse } from 'axios';

import { PaginatedResults } from 'types/Query';
import { request } from 'clients/baseClient';

import {
  UserSignupWithEmailAndPassword,
  UserCreate,
  UserEdit,
  User,
  UserApi,
  GetUsersParams,
  UserInviteApi,
  UserInvite,
} from './userClient.types';
import {
  mapInviteData,
  mapSignUpData,
  mapUserCrudData,
  mapUserData,
} from './userClient.formatter';

const usersApiBaseUrl = process.env.REACT_APP_CLA_API_URL;

const signupWithEmailAndPasswordInvitation = (data: UserSignupWithEmailAndPassword) => {
  return request({
    authenticate: false,
    options: {
      url: `${usersApiBaseUrl}/signup/`,
      method: 'POST',
      data: mapSignUpData(data),
    },
  });
};

const signupWithSSO = () => {
  return request({
    options: {
      url: `${usersApiBaseUrl}/signup-with-sso/`,
      method: 'POST',
    },
  });
};

const signupWithSSOInvitation = (invitationToken?: string) => {
  return request({
    options: {
      url: `${usersApiBaseUrl}/signup-with-sso/invitation/`,
      method: 'POST',
      data: {
        user_invitation_id: invitationToken,
      },
    },
  });
};

const getMe = (): Promise<AxiosResponse<User>> => {
  return request({
    options: {
      url: `${usersApiBaseUrl}/users/me`,
      method: 'GET',
    },
  }).then((data: AxiosResponse<UserApi>) => ({
    ...data,
    data: mapUserData(data.data),
  }));
};

const getUser = (params: { id: number | string }): Promise<AxiosResponse<User>> => {
  return request({
    options: {
      url: `${usersApiBaseUrl}/users/${params.id}`,
      method: 'GET',
    },
  }).then((data: AxiosResponse<UserApi>) => ({
    ...data,
    data: mapUserData(data.data),
  }));
};

const getUsers = (params: GetUsersParams): Promise<AxiosResponse<PaginatedResults<User>>> => {
  return request({
    options: {
      url: `${usersApiBaseUrl}/users`,
      method: 'GET',
      params: {
        type: params.type,
        company: params.company,
        search: params.search,
      },
    },
  }).then((data: AxiosResponse<PaginatedResults<UserApi>>) => ({
    ...data,
    data: {
      ...data.data,
      results: data.data.results.map(mapUserData),
    },
  }));
};

const createUser = (data: UserCreate): Promise<AxiosResponse<User>> => {
  return request({
    options: {
      url: `${usersApiBaseUrl}/users/`,
      method: 'POST',
      data: mapUserCrudData(data),
    },
  }).then((data: AxiosResponse<UserApi>) => ({
    ...data,
    data: mapUserData(data.data),
  }));
};

const editUser = (data: UserEdit): Promise<AxiosResponse<User>> => {
  return request({
    options: {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      url: `${usersApiBaseUrl}/users/${data.id}/`,
      method: 'PUT',
      data: mapUserCrudData(data),
    },
  }).then((data: AxiosResponse<UserApi>) => ({
    ...data,
    data: mapUserData(data.data),
  }));
};

const deleteUser = (params: { id: number | string }): Promise<AxiosResponse> => {
  return request({
    options: {
      url: `${usersApiBaseUrl}/users/${params.id}/`,
      method: 'DELETE',
    },
  });
};

const resetPassword = (email: string) => {
  return request({
    options: {
      url: `${usersApiBaseUrl}/users/reset-password/`,
      method: 'POST',
      data: {
        email: email,
      },
    },
  });
};


const inviteUser = (data: { email: string, auth_group: number }): Promise<AxiosResponse<any>> => {
  return request({
    options: {
      url: `${usersApiBaseUrl}/user-invitations/`,
      method: 'POST',
      data,
    },
  }).then((data: AxiosResponse<{ invitation_id: string }>) => ({
    ...data,
    data: {
      invitationId: data.data.invitation_id,
    },
  }));
};

const getInvite = (params: { id: string }): Promise<AxiosResponse<UserInvite>> => {
  return request({
    options: {
      url: `${usersApiBaseUrl}/user-invitations/${params.id}/`,
      method: 'GET',
    },
    authenticate: false,
  }).then((data: AxiosResponse<UserInviteApi>) => ({
    ...data,
    data: mapInviteData(data.data),
  }));
};

export const usersClient = {
  createUser,
  deleteUser,
  editUser,
  getMe,
  getUser,
  getUsers,
  inviteUser,
  getInvite,
  resetPassword,
  signupWithEmailAndPasswordInvitation,
  signupWithSSO,
  signupWithSSOInvitation,
};

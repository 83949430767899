import React, { FC, useContext } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';

import { CompanyContext, LocalizationContext } from 'contexts';
import { useUsers } from 'shared/hooks/useUsers';
import { BodyLoading } from 'shared/components/layout/BodyLoading/BodyLoading';
import { Filters } from 'views/LoggedIn/Team/partials/TeamHeader';
import { UserRow } from 'views/LoggedIn/Team/components/UserRow/UserRow';

interface Props {
  filters: Filters;
}

export const TeamList: FC<Props> = ({ filters }) => {

  const { dictionary } = useContext(LocalizationContext);
  const { company } = useContext(CompanyContext);

  const { users, status } = useUsers({
    company: company?.id,
    ...filters,
  });

  if (status === 'loading' || !users) {
    return <BodyLoading height="100vh"/>;
  }

  return (
    <TableContainer>
      <Table sx={{ minWidth: 650 }} aria-label="team table">
        <TableHead color="primary">
          <TableRow>
            <TableCell sx={{ color: '#A3A3A3' }}>{dictionary.team.detailsLabel}</TableCell>
            <TableCell align="right"/>
          </TableRow>
        </TableHead>
        <TableBody>
          {users.map(user => (
            <UserRow
              key={user.id}
              {...user}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

import React, { FC, useContext } from 'react';
import { Box, Typography } from '@mui/material';
import { isEmpty } from 'lodash';

import { primaryBrandOrange } from 'shared/styles/muiTheme';
import classnames from 'classnames';
import { LayoutContext, LocalizationContext } from 'contexts';

export type Footnote = {
  number: number;
  text: string;
  is_renvoi: boolean;
  is_full_renvoi: boolean;
  is_parent_full_renvoi: boolean;
};

export const Footnotes: FC = () => {
  const { dictionary } = useContext(LocalizationContext);
  const { layoutTempState } = useContext(LayoutContext);

  if (isEmpty(layoutTempState?.footnotes)) {
    return null;
  }

  return (
    <Box className="page-break">
      <Typography
        variant="h4"
        mb={3}
      >
        {dictionary.document.footnotesTitle}
      </Typography>
      <ol>
        {layoutTempState?.footnotes.map(({
          text,
          is_renvoi,
          is_full_renvoi,
          is_parent_full_renvoi,
        }: Footnote) => (
          <li
            style={{ color: primaryBrandOrange, marginBottom: '8px' }}
            className={
              classnames({
                'is-full-renvoi': is_full_renvoi || is_parent_full_renvoi,
              })
            }
          >
            <Typography
              component="div"
              color={primaryBrandOrange}
              fontWeight={400}
              display="flex"
            >
              <span
                className={
                  classnames({
                    'is-renvoi': is_renvoi,
                  })
                }
                dangerouslySetInnerHTML={{ __html: text }}
              />
            </Typography>
          </li>
        ))}
      </ol>
    </Box>
  );
};
